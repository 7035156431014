import { Settings } from "luxon";
import { Constructor } from "../types";
import { clearState, storeState } from "../util/taui-pref-storage";
import { TauiBaseEl } from "./taui-base-mixin";
import { identityGetIdentity } from "../data/identity/identity";
import { fireEvent } from "../common/dom/fire_event";
import { polyfillsLoaded } from "../common/translations/localize";
import { getMainError } from "../common/util/manager_error";
import { saveTokens } from "../common/auth/token_storage";

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-refresh-current-user": undefined;
  }
}

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("taui-logout", () => this._handleLogout());
      this.addEventListener("taui-refresh-current-user", async () => {
        await this._getOwnIdentity();
      });
    }

    protected async tauiConnected() {
      super.tauiConnected();
      await this._getOwnIdentity();
    }

    private async _handleLogout() {
      try {
        await this.taui!.auth.revoke();
        // this.taui!.connection.close();
        clearState();
        document.location.href = "/";
      } catch (err: any) {
        // eslint-disable-next-line
        console.error(err);
        alert("Log out failed");
      }
    }

    private async _getOwnIdentity() {
      await identityGetIdentity(this.taui!, this.taui!.auth.idIdentity, {
        expandAndPermission: [
          { expand: "ssos", permission: "identity.identity.list" },
          // Expand roles to get resource group in all app
          { expand: "roles", permission: "identity.role.list" },
        ],
      }).then(
        async (data) => {
          const dataUser = { ...(data?.result || {}) };

          if (
            dataUser.metadata?.config?.locale?.language &&
            this.taui!.locale.language !==
              dataUser.metadata.config.locale.language
          ) {
            fireEvent(
              this as any,
              "taui-language-select",
              dataUser.metadata.config.locale.language
            );
          }

          if (
            dataUser.metadata?.config?.locale?.number_format &&
            this.taui!.locale.number_format !==
              dataUser.metadata.config.locale.number_format
          ) {
            fireEvent(
              this as any,
              "taui-number-format-select",
              dataUser.metadata.config.locale.number_format
            );
          }

          if (
            dataUser.metadata?.config?.locale?.time_format &&
            this.taui!.locale.time_format !==
              dataUser.metadata.config.locale.time_format
          ) {
            fireEvent(
              this as any,
              "taui-time-format-select",
              dataUser.metadata.config.locale.time_format
            );
          }

          let time_zone;
          if (
            dataUser.metadata?.config?.locale?.time_zone &&
            this.taui!.locale.time_zone !==
              dataUser.metadata.config.locale.time_zone
          ) {
            time_zone = dataUser.metadata.config.locale.time_zone;
            fireEvent(this as any, "taui-time-zone-select", time_zone);
          } else {
            time_zone = "browser";
            if (this.taui!.locale.time_zone !== time_zone)
              fireEvent(this as any, "taui-time-zone-select", time_zone);
          }
          // Configure the default time zone
          Settings.defaultZone =
            time_zone === "browser"
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : time_zone;

          if (__BUILD__ === "latest" && polyfillsLoaded) {
            polyfillsLoaded.then(() => {
              if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
                // @ts-ignore
                Intl.DateTimeFormat.__setDefaultTimeZone(time_zone);
              }
            });
          } else if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
            // @ts-ignore
            Intl.DateTimeFormat.__setDefaultTimeZone(time_zone);
          }

          this._updateTaui({ user: dataUser });
          storeState(this.taui!);
        },
        (err) => {
          if (err.error)
            fireEvent(this as any, "taui-notification", {
              message: getMainError(this.taui, err),
              type: "error",
            });

          if (err.status_code === 401) {
            saveTokens(null);
            location.reload();
          }
        }
      );
    }
  };
