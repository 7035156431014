import { TucanoAdminUI } from "../types";
import {
  ResponseDTODispatchMode,
  ResponseDTOListImageTypeDTO,
  ResponseDTOVoid,
} from "../openapi/cms-api";
import { TauiServices } from "../types/structure";

export const fetchCmsConfigurationLanguage = async (taui: TucanoAdminUI) =>
  taui.callApi<{
    result: any;
  }>(
    "GET",
    TauiServices.cms,
    "/configuration/language",
    "locale.configuration.read"
  );

export const fetchCmsConfigurationTimezone = async (taui: TucanoAdminUI) =>
  taui.callApi<{
    result: any;
  }>(
    "GET",
    TauiServices.cms,
    "/configuration/timezone",
    "crm.configuration.read"
  );

export const fetchCmsAssetImageType = async (taui: TucanoAdminUI) =>
  taui.callApi<ResponseDTOListImageTypeDTO>(
    "GET",
    TauiServices.cms,
    "/asset/image/type",
    "cms.asset.list"
  );

export const cmsAddNewImage = async (taui: TucanoAdminUI, params) =>
  taui.callApi<{
    result: any;
  }>("PUT", TauiServices.cms, "/image", undefined, params);

export const assetAddExistingImage = async (
  taui: TucanoAdminUI,
  objectId,
  imageId,
  params
) =>
  taui.callApi<ResponseDTOVoid>(
    "PUT",
    TauiServices.cms,
    `/asset/${objectId}/image/${imageId}`,
    "cms.asset.update",
    params
  );

export const cmsListDispatchMode = async (taui: TucanoAdminUI) =>
  taui.callApi<ResponseDTODispatchMode>(
    "GET",
    TauiServices.cms,
    "/dispatchmode/transcoding",
    "cms.driver.list"
  );
