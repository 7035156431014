import { Auth } from "../data/auth";
import { Connection } from "../data/connection";
import { broadcastConnectionStatus } from "../data/connection-status";
import { translationMetadata } from "../resources/translations-metadata";
import { Constructor } from "../types";
import { TauiConfig } from "../types/config";
import { ERR_INVALID_AUTH } from "../types/errors";
import { fetchWithAuth } from "../util/fetch-with-auth";
import tauiCallApi from "../util/taui-call-api";
import { getState } from "../util/taui-pref-storage";
import { getLocalLanguage } from "../util/taui-translation";
import { TauiBaseEl } from "./taui-base-mixin";
import { tauiStructure } from "../resources/taui-structure";
import { NumberFormat, TimeFormat } from "../data/translation";
import { forwardHaptic } from "../data/haptics";

/** Panel to show when no panel is picked. */
const DEFAULT_PANEL = "dashboard";
export const DEFAULT_SELECTED_TAB = "dashboard/taui-dashboard";

export const connectionMixin = <T extends Constructor<TauiBaseEl>>(
  superClass: T
) =>
  class extends superClass {
    private __callCount = 0;

    protected initializeTaui(config: TauiConfig, auth: Auth, conn: Connection) {
      const language = getLocalLanguage();

      this.taui = {
        tauiVersion: "",
        app: null as any,
        auth,
        connection: conn,
        connected: true,
        config: config,
        structure: tauiStructure,
        themes: null as any,
        panels: null as any,
        components: null as any,
        panelUrl: (this as any)._panelUrl,
        services: null as any,
        language: getLocalLanguage(),
        selectedLanguage: null,
        selectedDashboard: -1,
        previousIdentity: null as any,
        selectedTab: DEFAULT_SELECTED_TAB,
        tabs: [],
        dynamicColumn: [],
        locale: {
          language,
          number_format: NumberFormat.language,
          time_format: TimeFormat.language,
          time_zone: "browser",
        },
        resources: null as any,
        localize: () => "",
        translationMetadata,
        dockedSidebar: "docked",
        vibrate: true,
        suspendWhenHidden: true,
        defaultPanel: DEFAULT_PANEL,
        user: null as any,
        backendLanguages: null as any,
        backendSupportedLanguages: null as any,
        currentPermissions: null as any,
        currentPermissionsRG: null as any,
        loading: false,
        hasPermission: (permission: string, idResourceGroup?: any) => {
          if (permission) {
            if (
              (this as any).taui &&
              (this as any).taui!.currentPermissions &&
              !(this as any).taui!.currentPermissions.includes(permission)
            ) {
              return false;
            }

            if (idResourceGroup && idResourceGroup.length > 0) {
              let hasPermissionRG = false;
              if ((this as any).taui!.currentPermissionsRG) {
                (this as any).taui!.currentPermissionsRG.forEach((el: any) => {
                  if (el.idRole !== 3) {
                    if (el.idResourceGroup === null) {
                      hasPermissionRG = true;
                      return;
                    }
                    if (idResourceGroup.includes(el.idResourceGroup)) {
                      if (el.permissions.includes(permission)) {
                        hasPermissionRG = true;
                      }
                    }
                  }
                });
              }
              return hasPermissionRG;
            }
          }

          return true;
        },
        hasService: (service: string) => {
          if (
            (this as any).taui &&
            (this as any).taui!.config &&
            (this as any).taui!.config.components
          ) {
            const component = (this as any).taui!.config.components.find(
              (el: any) => el.displayName === service
            );

            if (component) {
              return true;
            }
          }

          return false;
        },
        upload: {
          current: undefined,
          next: [],
        },
        tauiUrl: (path = "") => new URL(path, auth.data.tauiUrl).toString(),
        callApi: async (
          method,
          service,
          path,
          permission,
          parameters,
          headers
        ) => {
          if (service !== "proxy") {
            if (!this.taui!.hasService(service)) {
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw {
                error: `ui.api.services.missing_service,service,${service}`,
                status_code: 0,
                body: null,
              };
            }

            if (!this.taui!.hasPermission(permission)) {
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw {
                error: `ui.api.permissions.missing_permission,permission,${permission}`,
                status_code: 0,
                body: null,
              };
            }

            if (parameters) {
              if (parameters.expandAndPermission?.length > 0) {
                const expandArray: any = [];
                parameters.expandAndPermission.forEach((entry) => {
                  if (
                    entry.expand &&
                    (!entry.permission ||
                      this.taui!.hasPermission(String(entry.permission)) ||
                      this.taui!.hasPermission(
                        String(entry.permission).replace(".list", ".read")
                      ))
                  ) {
                    expandArray.push(entry.expand);
                  }
                });
                if (expandArray.length > 0) {
                  parameters.expand = expandArray.join();
                } else {
                  delete parameters.expand;
                }

                delete parameters.expandAndPermission;
              } else {
                delete parameters.expandAndPermission;
              }
            }
          }

          this._updateTaui({ loading: true });
          this.__callCount += 1;

          try {
            const resp = await tauiCallApi(
              auth,
              method,
              service,
              path,
              parameters,
              headers
            );

            this.__callCount -= 1;
            setTimeout(() => {
              if (this.__callCount === 0) {
                this._updateTaui({ loading: false });
              }
            }, 250);

            return await (resp as Promise<any>);
          } catch (err: any) {
            this.__callCount -= 1;
            setTimeout(() => {
              if (this.__callCount === 0) this._updateTaui({ loading: false });
            }, 250);

            forwardHaptic("failure");

            let errorMessage = "";

            if (err.status_code === 0 || err.status_code === 502) {
              errorMessage = "ui.api.http_error.service_not_available";
            } else if (err.status_code === 403) {
              errorMessage = "ui.api.http_error.access_denied";
            } else if (err.status_code === 500) {
              errorMessage = "ui.api.http_error.internal_server_error";
            }

            if (errorMessage)
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw {
                error: errorMessage,
                status_code: err.status_code,
                body: err.body,
              };

            if (__DEV__) {
              // eslint-disable-next-line no-console
              console.error(
                "Error calling api",
                method,
                service,
                path,
                permission,
                err
              );
            }

            throw err;
          }

          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw {
            error: undefined,
            status_code: undefined,
            body: undefined,
          };
        },
        fetchWithAuth: (
          path: string,
          init: Parameters<typeof fetchWithAuth>[2]
        ) => fetchWithAuth(auth, `${auth.data.tauiUrl}${path}`, init),
        ...getState(),
        ...this._pendingTaui,
      };

      this.tauiConnected();
    }

    protected tauiConnected() {
      super.tauiConnected();

      const conn = this.taui!.connection;

      broadcastConnectionStatus("connected");

      conn.addEventListener("ready", () => this.tauiReconnected());
      conn.addEventListener("disconnected", () => this.tauiDisconnected());
      // If we reconnect after losing connection and auth is no longer valid.
      conn.addEventListener("reconnect-error", (_conn, err) => {
        if (err === ERR_INVALID_AUTH) {
          broadcastConnectionStatus("auth-invalid");
          location.reload();
        }
      });
    }

    protected tauiReconnected() {
      super.tauiReconnected();

      this._updateTaui({ connected: true });
      broadcastConnectionStatus("connected");
    }

    protected tauiDisconnected() {
      super.tauiDisconnected();
      this._updateTaui({ connected: false });
      broadcastConnectionStatus("disconnected");
    }
  };
