import { TauiStructure } from "../types/structure";

export const tauiStructure: TauiStructure = {
  baseServices: [
    {
      displayName: "dashboard",
    },
    {
      displayName: "system",
    },
  ],
  services: {
    dashboard: {
      id: "taui-dashboard",
      logo: "home",
      base: "dashboard/taui-dashboard",
    },
    system: {
      id: "system",
      logo: "cog",
      base: "system/",
      services: ["identity"],
      items: [
        {
          id: "taui-system-configuration",
          path: "user/taui-system-configuration",
          permission: "identity.password-policy.list",
          services: ["identity"],
        },
        {
          id: "taui-role-list",
          path: "role/taui-role-list",
          permission: "identity.role.list",
          services: ["identity"],
          items: [
            {
              id: "taui-role",
              path: "role/taui-role",
            },
          ],
        },
        {
          id: "taui-resource-group-list",
          path: "resource-group/taui-resource-group-list",
          permission: "identity.resource-group.list",
          services: ["identity"],
          items: [
            {
              id: "taui-resource-group",
              path: "resource-group/taui-resource-group",
            },
          ],
        },
        {
          id: "taui-user-list",
          path: "user/taui-user-list",
          permission: "identity.identity.list",
          services: ["identity"],
          items: [
            {
              id: "taui-user",
              path: "user/taui-user",
            },
          ],
        },
        {
          id: "taui-version",
          path: "version/taui-version",
          permission: "",
        },
      ],
    },
    cms: {
      id: "cms",
      logo: "video",
      base: "cms/",
      services: ["cms", "studio", "npvr", "catchup", "recommendation"],
      items: [
        {
          id: "live",
          services: ["cms"],
          items: [
            {
              id: "taui-channel-list",
              path: "live/channel/taui-channel-list",
              permission: "cms.live.channel.list",
              services: ["cms"],
              items: [
                {
                  id: "taui-channel",
                  path: "live/channel/taui-channel",
                },
              ],
            },
            {
              id: "taui-service-plan-list",
              path: "live/service-plan/taui-service-plan-list",
              permission: "cms.live.serviceplan.list",
              services: ["cms"],
              items: [
                {
                  id: "taui-service-plan",
                  path: "live/service-plan/taui-service-plan",
                },
              ],
            },
            {
              id: "taui-epg-event-list",
              path: "live/epg-event/taui-epg-event-list",
              permission: "cms.live.epg.event.list",
              services: ["cms"],
              items: [
                {
                  id: "taui-epg-event",
                  path: "live/epg-event/taui-epg-event",
                },
              ],
            },
            {
              id: "taui-epg-provider-list",
              path: "live/epg-provider/taui-epg-provider-list",
              permission: "cms.live.epg.provider.list",
              services: ["cms"],
              items: [
                {
                  id: "taui-epg-provider",
                  path: "live/epg-provider/taui-epg-provider",
                },
              ],
            },
            {
              id: "taui-live-stream-package-list",
              path: "live/live-stream-package/taui-live-stream-package-list",
              permission: "cms.live.package.list",
              services: ["cms", "npvr", "catchup"],
              items: [
                {
                  id: "taui-live-stream-package",
                  path: "live/live-stream-package/taui-live-stream-package",
                },
              ],
            },
          ],
        },
        {
          id: "vod",
          services: ["cms", "studio"],
          items: [
            {
              id: "taui-vod-catalog-list",
              path: "vod/vod-catalog/taui-vod-catalog-list",
              permission: "cms.vod.catalog.list",
              services: ["cms"],
              items: [
                {
                  id: "taui-vod-catalog-publication",
                  path: "vod/vod-catalog/publication/taui-vod-catalog-publication",
                  items: [
                    {
                      id: "taui-vod-catalog-hierarchy",
                      path: "vod/vod-catalog/taui-vod-catalog-hierarchy",
                      items: [
                        {
                          id: "taui-vod-category",
                          path: "vod/vod-category/taui-vod-category",
                        },
                        {
                          id: "taui-vod-asset",
                          path: "vod/vod-asset/taui-vod-asset",
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "taui-vod-catalog-source",
                  path: "vod/vod-catalog/source/taui-vod-catalog-source",
                  items: [
                    {
                      id: "taui-vod-catalog-hierarchy",
                      path: "vod/vod-catalog/taui-vod-catalog-hierarchy",
                      items: [
                        {
                          id: "taui-vod-category",
                          path: "vod/vod-category/taui-vod-category",
                        },
                        {
                          id: "taui-vod-asset",
                          path: "vod/vod-asset/taui-vod-asset",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: "taui-vod-package-list",
              path: "vod/vod-package/taui-vod-package-list",
              permission: "cms.vod.package.list",
              services: ["cms"],
              items: [
                {
                  id: "taui-vod-package",
                  path: "vod/vod-package/taui-vod-package",
                },
              ],
            },
            {
              id: "taui-studio",
              path: "vod/studio/taui-studio",
              permission: "studio.asset.list",
              services: ["studio"],
            },
          ],
        },
        {
          id: "taui-series-list",
          path: "series/series/taui-series-list",
          permission: "cms.series.list",
          services: ["cms"],
          items: [
            {
              id: "taui-series",
              path: "series/series/taui-series",
            },
            {
              id: "taui-season",
              path: "series/season/taui-season",
            },
          ],
        },
        {
          id: "taui-recommendation",
          path: "recommendation/taui-recommendation",
          permission: "recommendation.config.read",
          services: ["recommendation"],
        },
      ],
    },
    publication: {
      id: "publication",
      logo: "tv",
      base: "publication/",
      services: ["publication", "identity"],
      items: [
        {
          id: "taui-portal-list",
          path: "portal/taui-portal-list",
          permission: "publication.portal.list",
          services: ["publication"],
          items: [
            {
              id: "taui-portal",
              path: "portal/taui-portal",
            },
          ],
        },
        {
          id: "taui-client-list",
          path: "client/taui-client-list",
          permission: "identity.client.list",
          services: ["identity"],
          items: [
            {
              id: "taui-client",
              path: "client/taui-client",
            },
          ],
        },
        {
          id: "taui-homepage-list",
          path: "homepage/taui-homepage-list",
          permission: "publication.homepage.list",
          services: ["publication"],
          items: [
            {
              id: "taui-homepage",
              path: "homepage/taui-homepage",
            },
          ],
        },
        {
          id: "taui-global-image-list",
          path: "global-image/taui-global-image-list",
          permission: "publication.global-image.list",
          services: ["publication"],
        },
        {
          id: "taui-editorial-section-list",
          path: "editorial-section/taui-editorial-section-list",
          permission: "publication.editorial-section.list",
          services: ["publication"],
          items: [
            {
              id: "taui-editorial-section-legacy-faq",
              path: "editorial-section/taui-editorial-section-legacy-faq",
            },
            {
              id: "taui-editorial-section-new-section",
              path: "editorial-section/taui-editorial-section-new-section",
            },
          ],
        },
      ],
    },
    crm: {
      id: "crm",
      logo: "users",
      base: "crm/",
      services: ["crm"],
      items: [
        {
          id: "taui-crm-configuration",
          path: "customer/taui-crm-configuration",
          permission: "crm.configuration.read",
          services: ["crm"],
        },
        {
          id: "taui-customer-list",
          path: "customer/taui-customer-list",
          permission: "crm.customer.list",
          services: ["crm"],
          items: [
            {
              id: "taui-customer",
              path: "customer/taui-customer",
            },
          ],
        },
      ],
    },
    monetization: {
      id: "monetization",
      logo: "lightbulb-dollar",
      base: "monetization/",
      services: ["crm", "suboffer", "billing"],
      items: [
        {
          id: "taui-monetization-brand-list",
          path: "monetization/taui-monetization-brand-list",
          permission: "crm.brand.list",
          services: ["crm"],
          items: [
            {
              id: "taui-monetization-brand",
              path: "monetization/taui-monetization-brand",
            },
          ],
        },
        {
          id: "taui-monetization-offer-list",
          path: "monetization/taui-monetization-offer-list",
          permission: "suboffer.offer.list",
          services: ["suboffer"],
          items: [
            {
              id: "taui-monetization-offer",
              path: "monetization/taui-monetization-offer",
            },
          ],
        },
        {
          id: "taui-monetization-offer-groups-list",
          path: "monetization/taui-monetization-offer-groups-list",
          permission: "suboffer.group.list",
          services: ["suboffer"],
          items: [
            {
              id: "taui-monetization-offer-groups",
              path: "monetization/taui-monetization-offer-groups",
            },
          ],
        },
        {
          id: "taui-monetization-discount-list",
          path: "monetization/taui-monetization-discount-list",
          permission: "billing.discount.read",
          services: ["billing", "suboffer"],
          items: [
            {
              id: "taui-monetization-discount",
              path: "monetization/taui-monetization-discount",
            },
          ],
        },
        {
          id: "taui-monetization-order-list",
          path: "monetization/taui-monetization-order-list",
          permission: "billing.order.read",
          services: ["billing"],
          items: [],
        },
      ],
    },
    communication: {
      id: "communication",
      logo: "paper-plane",
      base: "communication/",
      services: ["crm"],
      items: [
        {
          id: "taui-communication-notification-template-list",
          path: "communication/notification/template/taui-communication-notification-template-list",
          permission: "crm.notification.template.list",
          services: ["crm"],
          items: [
            {
              id: "taui-communication-notification-template",
              path: "communication/notification/template/taui-communication-notification-template",
            },
          ],
        },
        {
          id: "taui-communication-notification-history-list",
          path: "communication/notification/history/taui-communication-notification-history-list",
          permission: "crm.notification.history.list",
          services: ["crm"],
          items: [
            {
              id: "taui-communication-notification-history",
              path: "communication/notification/history/taui-communication-notification-history",
            },
          ],
        },
      ],
    },
    realtime: {
      id: "realtime",
      logo: "stopwatch",
      base: "realtime/",
      services: ["realtime"],
      items: [
        {
          id: "taui-realtime-message-list",
          path: "message/taui-realtime-message-list",
          permission: "realtime.message.list",
          services: ["realtime"],
          items: [
            {
              id: "taui-realtime-message",
              path: "message/taui-realtime-message",
            },
          ],
        },
      ],
    },
    support: {
      id: "support",
      logo: "ticket",
      base: "support/",
      services: ["entitlements"],
      items: [
        {
          id: "taui-support-entitlements-list",
          path: "entitlements/taui-support-entitlements-list",
          permission: "entitlements.entitlement.read",
          items: [
            {
              id: "taui-support-entitlements",
              path: "entitlements/taui-support-entitlements",
            },
          ],
          services: ["entitlements"],
        },
      ],
    },
  },
};
