import "@polymer/paper-styles/paper-styles";
import "@polymer/polymer/lib/elements/custom-style";
import { derivedStyles } from "./styles";

export const DEFAULT_PRIMARY_COLOR = "#e42a2b";
export const DEFAULT_ACCENT_COLOR = "#e95b28";

const documentContainer = document.createElement("template");
documentContainer.setAttribute("style", "display: none;");

documentContainer.innerHTML = `<custom-style>
  <style>
    /*
      TAUI default styles.

      In Polymer 2.0, default styles should to be set on the html selector.
      (Setting all default styles only on body breaks shadyCSS polyfill.)
    */
    html {
        font-size: 11pt;
        height: 100vh;
        
        scrollbar-color: var(--scrollbar-thumb-color) transparent;
        scrollbar-width: thin;

        /* text */
        --primary-text-color: #212121;
        --secondary-text-color: #727272;
        --text-primary-color: #ffffff;
        --text-light-primary-color: #212121;
        --disabled-text-color: #bdbdbd;
        
        /* main interface color */
        --primary-color: ${DEFAULT_PRIMARY_COLOR};
        --accent-color: ${DEFAULT_ACCENT_COLOR};
        --divider-color: rgba(0, 0, 0, .12);
        
        --error-color: #d8373a;
        --warning-color: #FF9800;
        --success-color: #0f9d58;
        --info-color: #4285f4;
        
        /* background and sidebar */
        --card-background-color: #ffffff;
        --primary-background-color: #fafafa;
        --secondary-background-color: #e5e5e5; /* behind the cards on state */
        
        /* for header */
        --header-height: 50px;
        
        /* states */
        --state-icon-color: #44739e;
        /* an active state is anything that would require attention */
        --state-icon-active-color: #FDD835;
        /* an error state is anything that would be considered an error */
        /* --state-icon-error-color: #db4437; derived from error-color */
        
        --scrollbar-track-color: #edf2f7;
        --scrollbar-thumb-color: #cad9e7;

        --app-loader-background: #edf2f7;
        --app-select-color: #84bcff;
        --app-table-thead-color: #546e8c;
        --app-progress-bar-color: var(--info-color);
        --app-edited-field-color: #feffc8;
        
        /*
          Paper-styles color.html dependency is stripped on build.
          When a default paper-style color is used, it needs to be copied
          from paper-styles/color.html to here.
        */        
        --paper-grey-50: #fafafa; /* default for: --mwc-switch-unchecked-button-color */
        --paper-grey-200: #eeeeee;  /* for taui-date-picker-style */
        --paper-grey-500: #9e9e9e;  /* --label-badge-grey */
        
        --paper-green-400: #66bb6a;
        --paper-blue-400: #42a5f5;
        --paper-orange-400: #ffa726;
          
        /* buttons */
        --paper-button-color: #006bb5;
        --paper-button-text-color: #fff;
        --paper-button-hover-color: #005999;
        --paper-button-active-color: #004a80;
        
        /* opacity for dark text on a light background */
        --dark-divider-opacity: 0.12;
        --dark-disabled-opacity: 0.38; /* or hint text or icon */
        --dark-secondary-opacity: 0.54;
        --dark-primary-opacity: 0.87;
        
        /* opacity for light text on a dark background */
        --light-divider-opacity: 0.12;
        --light-disabled-opacity: 0.3; /* or hint text or icon */
        --light-secondary-opacity: 0.7;
        --light-primary-opacity: 1.0;
        
        /* rgb */
        --rgb-primary-color: 44, 62, 80;
        --rgb-accent-color: 229, 38, 41;
        --rgb-primary-text-color: 22, 46, 74;
        --rgb-secondary-text-color: 114, 114, 114;
        --rgb-text-primary-color: 255, 255, 255;
        --rgb-card-background-color: 255, 255, 255;
        
        /* input components */
        --input-idle-line-color: rgba(0, 0, 0, 0.42);
        --input-hover-line-color: rgba(0, 0, 0, 0.87);
        --input-disabled-line-color: rgba(0, 0, 0, 0.06);
        --input-outlined-idle-border-color: rgba(0, 0, 0, 0.38);
        --input-outlined-hover-border-color: rgba(0, 0, 0, 0.87);
        --input-outlined-disabled-border-color: rgba(0, 0, 0, 0.06);
        --input-fill-color: rgb(245, 245, 245);
        --input-disabled-fill-color: rgb(250, 250, 250);
        --input-ink-color: rgba(0, 0, 0, 0.87);
        --input-label-ink-color: rgba(0, 0, 0, 0.6);
        --input-disabled-ink-color: rgba(0, 0, 0, 0.37);
        --input-dropdown-icon-color: rgba(0, 0, 0, 0.54);

        /* Vaadin typography */
        --material-h6-font-size: 1.25rem;
        --material-small-font-size: 0.875rem;
        --material-caption-font-size: 0.75rem;
        --material-button-font-size: 0.875rem;
        
        ${Object.entries(derivedStyles)
          .map(([key, value]) => `--${key}: ${value};`)
          .join("")}
    }
    
    body #tooltipDragAndDrop {
        position: absolute;
        padding: 5px 10px;
        border-radius: 3px;
        background-color: #555;
        color: #fff;
        text-align: center;
        font-weight: normal;
    }
    
    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background: var(--scrollbar-thumb-color);
    }

    ::-webkit-scrollbar-track {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background-color: var(--scrollbar-track-color);
    }
  </style>
</custom-style>`;

document.head.appendChild(documentContainer.content);
