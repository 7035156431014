import { TucanoAdminUI } from "../types";

const STORED_STATE = [
  "dockedSidebar",
  "selectedTheme",
  "selectedLanguage",
  "vibrate",
  "selectedDashboard",
  "previousIdentity",
  "selectedTab",
  "tabs",
  "dynamicColumn",
  "suspendWhenHidden",
  "enableShortcuts",
  "tauiVersion",
];
const STORAGE = window.localStorage || {};

export function storeState(taui: TucanoAdminUI) {
  try {
    STORED_STATE.forEach((key) => {
      const value = taui[key];
      STORAGE[key] = JSON.stringify(value === undefined ? null : value);
    });
  } catch (err) {
    // Safari throws exception in private mode
  }
}

export function getState() {
  const state: any = {};

  STORED_STATE.forEach((key) => {
    if (key in STORAGE) {
      let value = JSON.parse(STORAGE[key]);
      if (key === "selectedTheme" && typeof value === "string") {
        value = { theme: value };
      }
      if (key === "dockedSidebar" && typeof value === "boolean") {
        value = value ? "docked" : "auto";
      }
      state[key] = value;
    }
  });

  return state;
}

export function clearState() {
  const currentState = getState();

  // STORAGE is an object if localStorage not available.
  if (STORAGE.clear) {
    STORAGE.clear();
  }

  // Restore keeping state
  if (currentState) {
    STORAGE.previousIdentity = JSON.stringify(
      currentState?.previousIdentity === undefined
        ? null
        : currentState?.previousIdentity
    );
    STORAGE.selectedTab = JSON.stringify(
      currentState?.selectedTab === undefined ? null : currentState?.selectedTab
    );
    STORAGE.tabs = JSON.stringify(
      currentState?.tabs === undefined ? null : currentState?.tabs
    );
    STORAGE.dynamicColumn = JSON.stringify(
      currentState?.dynamicColumn === undefined
        ? null
        : currentState?.dynamicColumn
    );
  }
}
