import "@material/mwc-button";
import { css, CSSResultGroup, html, LitElement } from "lit";
import { property, state } from "lit/decorators";

class TauiInitPage extends LitElement {
  @property({ type: Boolean }) public error = false;

  @state() showProgressIndicator = false;

  private _showProgressIndicatorTimeout;

  protected render() {
    return html`
      ${this.error
        ? html`
            <p>Unable to connect to tucano Administration UI.</p>
            <mwc-button @click=${this._retry}>Retry</mwc-button>
          `
        : html` <div id="progress-indicator-wrapper">
              ${this.showProgressIndicator
                ? html`<taui-circular-progress active></taui-circular-progress>`
                : ""}
            </div>
            <div id="loading-text">Loading data</div>`}
    `;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    clearTimeout(this._showProgressIndicatorTimeout);
  }

  protected firstUpdated() {
    this._showProgressIndicatorTimeout = setTimeout(async () => {
      await import("../components/taui-circular-progress");
      this.showProgressIndicator = true;
    }, 10);
  }

  private _retry() {
    location.reload();
  }

  static get styles(): CSSResultGroup {
    return css`
      :host {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      #progress-indicator-wrapper {
        display: flex;
        align-items: center;
        margin: 25px 0;
        height: 50px;
      }
      p,
      #loading-text {
        max-width: 350px;
        color: var(--primary-text-color);
      }
    `;
  }
}

customElements.define("taui-init-page", TauiInitPage);

declare global {
  interface HTMLElementTagNameMap {
    "taui-init-page": TauiInitPage;
  }
}
