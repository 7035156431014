export const LAZY_LOAD_ROUTES = {
  /* Main */
  activesessions: () => import("../components/taui-active-sessions"),
  additionalmetadata: () =>
    import("../components/additional-metadata/taui-additional-metadata"),
  moduleimage: () => import("../components/image/taui-module-image"),
  modulemedia: () => import("../components/media/taui-module-media"),
  media: () => import("../components/media/taui-media"),

  /* Dashboard */
  dashboard: () => import("../panels/dashboard/taui-dashboard"),

  /* System */
  systemconfiguration: () =>
    import("../panels/system/system-configuration/taui-system-configuration"),
  rolelist: () => import("../panels/system/role/taui-role-list"),
  role: () => import("../panels/system/role/taui-role"),
  resourcegrouplist: () =>
    import("../panels/system/resource-group/taui-resource-group-list"),
  resourcegroup: () =>
    import("../panels/system/resource-group/taui-resource-group"),
  userlist: () => import("../panels/system/user/taui-user-list"),
  user: () => import("../panels/system/user/taui-user"),
  version: () => import("../panels/system/version/taui-version"),

  /* CMS */
  /* Live */
  channellist: () => import("../panels/cms/live/channel/taui-channel-list"),
  channel: () => import("../panels/cms/live/channel/taui-channel"),
  serviceplanlist: () =>
    import("../panels/cms/live/service-plan/taui-service-plan-list"),
  serviceplan: () =>
    import("../panels/cms/live/service-plan/taui-service-plan"),
  epgeventlist: () =>
    import("../panels/cms/live/epg-event/taui-epg-event-list"),
  epgevent: () => import("../panels/cms/live/epg-event/taui-epg-event"),
  epgproviderlist: () =>
    import("../panels/cms/live/epg-provider/taui-epg-provider-list"),
  epgprovider: () =>
    import("../panels/cms/live/epg-provider/taui-epg-provider"),
  /* Live stream package */
  livestreampackagelist: () =>
    import(
      "../panels/cms/live/live-stream-package/taui-live-stream-package-list"
    ),
  livestreampackage: () =>
    import("../panels/cms/live/live-stream-package/taui-live-stream-package"),

  /* VOD */
  vodcataloglist: () =>
    import("../panels/cms/vod/vod-catalog/taui-vod-catalog-list"),
  vodcatalogpublication: () =>
    import(
      "../panels/cms/vod/vod-catalog/publication/taui-vod-catalog-publication"
    ),
  vodcatalogsource: () =>
    import("../panels/cms/vod/vod-catalog/source/taui-vod-catalog-source"),
  vodcategory: () => import("../panels/cms/vod/vod-category/taui-vod-category"),
  vodcategorydetail: () =>
    import("../panels/cms/vod/vod-category/taui-vod-category-detail"),
  vodasset: () => import("../panels/cms/vod/vod-asset/taui-vod-asset"),
  vodpackagelist: () =>
    import("../panels/cms/vod/vod-package/taui-vod-package-list"),
  vodpackage: () => import("../panels/cms/vod/vod-package/taui-vod-package"),
  studio: () => import("../panels/cms/vod/studio/taui-studio"),

  /* Series */
  serieslist: () => import("../panels/cms/series/series/taui-series-list"),
  series: () => import("../panels/cms/series/series/taui-series"),

  /* Seasons */
  season: () => import("../panels/cms/series/seasons/taui-season"),

  /* Recommendation */
  recommendation: () =>
    import("../panels/cms/recommendation/taui-recommendation"),

  /* CRM */
  /* CRM Configuration */
  crmconfiguration: () =>
    import("../panels/crm/crm-configuration/taui-crm-configuration"),

  /* Customer */
  customerlist: () => import("../panels/crm/customer/taui-customer-list"),
  customer: () => import("../panels/crm/customer/taui-customer"),
  customeradministrative: () =>
    import("../panels/crm/customer/taui-customer-administrative"),
  customerprofile: () => import("../panels/crm/customer/taui-customer-profile"),
  customersubscription: () =>
    import("../panels/crm/customer/taui-customer-subscription"),
  customerorder: () => import("../panels/crm/customer/taui-customer-order"),
  customersupport: () => import("../panels/crm/customer/taui-customer-support"),
  customernotificationhistory: () =>
    import("../panels/crm/customer/taui-customer-notification-history"),

  /* Publication */
  /* Portal */
  portallist: () => import("../panels/publication/portal/taui-portal-list"),
  portal: () => import("../panels/publication/portal/taui-portal"),

  /* Client */
  clientlist: () => import("../panels/publication/client/taui-client-list"),
  client: () => import("../panels/publication/client/taui-client"),

  /* Homepage */
  homepagelist: () =>
    import("../panels/publication/homepage/taui-homepage-list"),
  homepage: () => import("../panels/publication/homepage/taui-homepage"),

  /* Global Image */
  globalimagelist: () =>
    import("../panels/publication/global-image/taui-global-image-list"),

  /* Editorial section */
  editorialsectionlist: () =>
    import(
      "../panels/publication/editorial-section/taui-editorial-section-list"
    ),
  editorialsectionlegacyfaq: () =>
    import(
      "../panels/publication/editorial-section/legacy-faq/taui-editorial-section-legacy-faq"
    ),
  editorialsectionnewsection: () =>
    import(
      "../panels/publication/editorial-section/new-section/taui-editorial-section-new-section"
    ),

  /* Monetization */
  /* Brands */
  monetizationbrandlist: () =>
    import("../panels/monetization/brand/taui-monetization-brand-list"),
  monetizationbrand: () =>
    import("../panels/monetization/brand/taui-monetization-brand"),
  /* Offers */
  monetizationofferlist: () =>
    import("../panels/monetization/offer/taui-monetization-offer-list"),
  monetizationoffer: () =>
    import("../panels/monetization/offer/taui-monetization-offer"),
  /* Offer groups */
  monetizationoffergroupslist: () =>
    import(
      "../panels/monetization/offer-groups/taui-monetization-offer-groups-list"
    ),
  monetizationoffergroups: () =>
    import(
      "../panels/monetization/offer-groups/taui-monetization-offer-groups"
    ),

  /* Discount */
  monetizationdiscountlist: () =>
    import("../panels/monetization/discount/taui-monetization-discount-list"),
  monetizationdiscount: () =>
    import("../panels/monetization/discount/taui-monetization-discount"),

  /* Order */
  monetizationorderlist: () =>
    import("../panels/monetization/order/taui-monetization-order-list"),

  /* Communication */
  /* Notification template */
  communicationnotificationtemplatelist: () =>
    import(
      "../panels/communication/notification/template/taui-communication-notification-template-list"
    ),
  communicationnotificationtemplate: () =>
    import(
      "../panels/communication/notification/template/taui-communication-notification-template"
    ),
  /* Notification history */
  communicationnotificationhistorylist: () =>
    import(
      "../panels/communication/notification/history/taui-communication-notification-history-list"
    ),
  communicationnotificationhistory: () =>
    import(
      "../panels/communication/notification/history/taui-communication-notification-history"
    ),

  /* Realtime */
  /* Message */
  realtimemessagelist: () =>
    import("../panels/realtime/message/taui-realtime-message-list"),
  realtimemessage: () =>
    import("../panels/realtime/message/taui-realtime-message"),

  /* Support */
  /* Entitlements */
  supportentitlementslist: () =>
    import("../panels/support/entitlements/taui-support-entitlements-list"),
  supportentitlements: () =>
    import("../panels/support/entitlements/taui-support-entitlements"),
};
