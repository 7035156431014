import { TucanoAdminUI } from "../../types";

export function computeRTL(taui: TucanoAdminUI, language?: string) {
  let lang = taui.language || "en";
  if (language) {
    lang = language;
  }

  if (taui.translationMetadata.translations[lang]) {
    return taui.translationMetadata.translations[lang].isRTL || false;
  }
  return false;
}

export function computeRTLDirection(taui: TucanoAdminUI) {
  return emitRTLDirection(computeRTL(taui));
}

export function emitRTLDirection(rtl: boolean) {
  return rtl ? "rtl" : "ltr";
}
