import { Constructor } from "../types";
import { connectionMixin } from "./connection-mixin";
import { dialogManagerMixin } from "./dialog-manager-mixin";
import NotificationMixin from "./notification-mixin";
import SidebarMixin from "./sidebar-mixin";
import { TauiBaseEl } from "./taui-base-mixin";
import ThemesMixin from "./themes-mixin";
import TranslationsMixin from "./translations-mixin";
import UploadMixin from "./upload-mixin";
import { hapticMixin } from "./haptic-mixin";
import { sideSheetManagerMixin } from "./side-sheet-manager-mixin";
import AuthMixin from "./auth-mixin";

const ext = <T extends Constructor>(baseClass: T, mixins): T =>
  mixins.reduceRight((base, mixin) => mixin(base), baseClass);

export class TauiElement extends ext(TauiBaseEl, [
  AuthMixin,
  ThemesMixin,
  connectionMixin,
  TranslationsMixin,
  SidebarMixin,
  NotificationMixin,
  dialogManagerMixin,
  sideSheetManagerMixin,
  UploadMixin,
  hapticMixin,
]) {}
