import { isObject, isString } from "../string/check_type";

export function getMainError(taui, err) {
  return (
    getTranslationKeyError(taui, err.error) +
    (err.body && err.body.message ? ": " + err.body.message : "")
  );
}

export function getFullError(taui, errObject) {
  const errorMessage: string[] = [];

  if (errObject.error)
    errorMessage.push(getTranslationKeyError(taui, errObject.error));

  if (errObject.body) {
    const messages = manageError(errObject.body);

    if (messages && messages.length > 0) {
      messages.forEach((message) =>
        errorMessage.push(getTranslationKeyError(taui, message))
      );
    }
  }

  return errorMessage && errorMessage.length > 0
    ? errorMessage.join(", ")
    : taui.localize("ui.common.unknown_error");
}

export function getTranslationKeyError(taui, message) {
  let messageTranslated;

  if (
    message &&
    typeof message === "string" &&
    message.startsWith("ui.") &&
    message.includes(",")
  ) {
    const [translationKey, variableKey, variable] = message.split(",");
    messageTranslated = taui.localize(translationKey, variableKey, variable);
  } else {
    messageTranslated = taui.localize(message);
  }

  if (
    messageTranslated &&
    messageTranslated.indexOf("Translation Error") === -1
  )
    return messageTranslated;
  return message || "";
}

export function manageError(data: any) {
  const messages: any = [];

  if (data) {
    if (
      Object.prototype.hasOwnProperty.call(data, "error") &&
      Object.prototype.hasOwnProperty.call(data.error, "details")
    ) {
      data.details = data.error.details;
    }

    if (
      Object.prototype.hasOwnProperty.call(data, "details") &&
      isObject(typeof data.details)
    ) {
      if (Object.prototype.hasOwnProperty.call(data, "message")) {
        messages.push(data.message);
      }

      // eslint-disable-next-line array-callback-return
      Object.keys(data.details).map((key) => {
        const field = key.endsWith("Aql") ? key.slice(0, -3) : key;

        let message = field + " : " + data.details[key];

        if (message.substr(0, 3) === "is ") {
          message = field + " " + message;
        }

        messages.push(message);
      });
    } else if (
      Object.prototype.hasOwnProperty.call(data, "details") &&
      isString(typeof data.details)
    ) {
      messages.push(data.details);
    } else {
      messages.push(data.message);
    }
  }

  return messages;
}

export function renderErrorOnDelete(
  title: string,
  checkErrorMessage: {
    message: string;
    details: string[];
  }
) {
  return `${title}\n\n${
    checkErrorMessage?.message || ""
  }\n\n${checkErrorMessage?.details?.join("\n")}`;
}
