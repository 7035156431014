import { TucanoAdminUI } from "../../types";
import { MediaDTO, MediaDTOList, MediaParams } from "../../openapi/studio-api";
import { TauiServices } from "../../types/structure";

export const studioListMedias = async (taui: TucanoAdminUI, idAsset: string) =>
  taui.callApi<MediaDTOList>(
    "GET",
    TauiServices.studio,
    `/asset/${idAsset}/media`,
    "studio.media.read"
  );

export const studioGetMedia = async (
  taui: TucanoAdminUI,
  idAsset: string,
  idMedia: string
) =>
  taui.callApi<MediaDTO>(
    "GET",
    TauiServices.studio,
    `/asset/${idAsset}/media/${idMedia}`,
    "studio.media.read"
  );

export const studioCreateMedia = async (
  taui: TucanoAdminUI,
  idAsset: string,
  params: MediaParams
) =>
  taui.callApi<MediaDTO>(
    "POST",
    TauiServices.studio,
    `/asset/${idAsset}/media`,
    "studio.asset.update",
    params
  );

export const studioUpdateMedia = async (
  taui: TucanoAdminUI,
  idAsset: string,
  idMedia: string,
  params: MediaParams
) =>
  taui.callApi<MediaDTO>(
    "PUT",
    TauiServices.studio,
    `/asset/${idAsset}/media/${idMedia}`,
    "studio.asset.update",
    params
  );

export const studioDeleteMedia = async (
  taui: TucanoAdminUI,
  idAsset: string,
  idMedia: string
) =>
  taui.callApi<void>(
    "DELETE",
    TauiServices.studio,
    `/asset/${idAsset}/media/${idMedia}`,
    "studio.media.delete"
  );
