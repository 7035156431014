import { clear, get, set, createStore, promisifyRequest } from "idb-keyval";
import { promiseTimeout } from "../common/util/promise-timeout";
import { iconMetadata } from "../resources/icon-metadata";
import { IconMeta } from "../types";

export interface Icons {
  [key: string]: DatabaseIcon;
}

export interface Chunks {
  [key: string]: Promise<Icons>;
}

export interface ChunksIcons {
  [key: string]: Chunks;
}

export interface DatabaseIcon {
  path?: string[];
  viewBox?: string;
  style?: string;
  svg?: string;
}

export const iconStore = {
  fal: new createStore("fal-icon-db", "fal-icon-store"),
  fad: new createStore("fad-icon-db", "fad-icon-store"),
  taui: new createStore("taui-icon-db", "taui-icon-store"),
};

export const ICON_PREFIXES = ["fal", "fad", "taui"];

export const FA_PREFIXES = ["fal", "fad"];

export interface toReadType {
  [key: string]: Array<
    [string, (iconPath: string | undefined) => void, (e: any) => void]
  >;
}

const toRead: toReadType = {
  fal: [],
  fad: [],
  taui: [],
};

// Queue up as many icon fetches in 1 transaction
export const getIcon = (iconPrefix: string, iconName: string) =>
  new Promise<string | undefined>((resolve, reject) => {
    toRead[iconPrefix].push([iconName, resolve, reject]);

    if (toRead[iconPrefix].length > 1) {
      return;
    }

    const readIcons = () =>
      iconStore[iconPrefix]("readonly", (store) => {
        for (const [iconName_, resolve_, reject_] of toRead[iconPrefix]) {
          promisifyRequest<string | undefined>(store.get(iconName_))
            .then((icon) => resolve_(icon))
            .catch((e) => reject_(e));
        }
        toRead[iconPrefix] = [];
      });

    promiseTimeout(1000, readIcons()).catch((e) => {
      // Firefox in private mode doesn't support IDB
      // Safari sometime doesn't open the DB so we time out
      for (const [, , reject_] of toRead[iconPrefix]) {
        reject_(e);
      }
      toRead[iconPrefix] = [];
    });
  });

export const findIconChunk = (iconPrefix, icon: string): string => {
  let lastChunk: IconMeta;
  for (const chunk of iconMetadata[iconPrefix].parts) {
    if (chunk.start !== undefined && icon < chunk.start) {
      break;
    }
    lastChunk = chunk;
  }
  return lastChunk!.file;
};

export const writeCache = async (chunksIcons: ChunksIcons) => {
  for (const [key, chunks] of Object.entries(chunksIcons)) {
    const keys = Object.keys(chunks);
    // eslint-disable-next-line no-await-in-loop
    const iconsSets: Icons[] = await Promise.all(Object.values(chunks));
    // We do a batch opening the store just once, for (considerable) performance
    iconStore[key]("readwrite", (store) => {
      iconsSets.forEach((icons, idx) => {
        Object.entries(icons).forEach(([name, path]) => {
          store.put(path, name);
        });
        delete chunks[keys[idx]];
      });
    });
  }
};

export const checkCacheVersion = () => {
  ICON_PREFIXES.forEach((iconPrefix, _index) => {
    get("_version", iconStore[iconPrefix]).then((version) => {
      if (!version) {
        set(
          "_version",
          iconMetadata[iconPrefix].version,
          iconStore[iconPrefix]
        );
      } else if (version !== iconMetadata[iconPrefix].version) {
        clear(iconStore[iconPrefix]).then(() =>
          set(
            "_version",
            iconMetadata[iconPrefix].version,
            iconStore[iconPrefix]
          )
        );
      }
    });
  });
};
