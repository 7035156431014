import { TauiConfig } from "../types/config";

export const tauiFakeHost =
  "https://adminui.staging-red-2022-01.labs.tucano.red";

export const tauiFakeConfig: TauiConfig = {
  backendHttpApi: "http://localhost:8080/",
  sentryConfig: {
    enabled: false,
    dsn: "https://c00c5b6cc7b941948e8adfc33b2060dc@sentry.labs.tucano.red/6020613",
    environment: "development",
    debug: true,
  },
  platformLanguage: "eng",
  platformSupportedLanguages: ["fra", "spa", "eng"],
  uiLanguages: ["ar", "en", "en-GB", "es", "fr", "pt-BR"],
  skin: "tucanored",
  oauthClientId: "tucanoTrustedUser",
  esIndexPrefix: "localhost_analytics",
  deviceConnectedSince: "10m",
  rememberTab: true,
  embedPlayer: true,
  playerTakeScreenshot: false,
  veygoKey: "ANBeemixDevelopment22Y3zJchM6dojZp5",
  deviceType: [
    {
      value: "*android*",
      name: "Android",
      backgroundColor: "#65738e",
    },
    {
      value: "*ios*",
      name: "iOS",
      backgroundColor: "#a92a2c",
    },
    {
      value: "*windows*",
      name: "Windows",
      backgroundColor: "#4d4f4a",
    },
    {
      value: "other_bucket_key",
      name: "Others",
      backgroundColor: "#000000",
    },
  ],
  dashboardRefreshTime: "300000",
  resourceGroupsLabels: ["brand", "reseller"],
  realTimeMessageActionType: [
    { value: "OPEN_APP", name: "Open app" },
    { value: "GO_TO_CHANNEL", name: "Go to channel", extra: "id_channel" },
  ],
  uploadChunkSize: 1048576,
  uploadChunkSimultaneous: 3,
  uploadMaxChunkRetries: 10,
  uploadSimultaneous: 1,
  vodStudioFileExtensionTechnology: [
    {
      fileExtension: "m3u8",
      technology: "HLS",
    },
    {
      fileExtension: "ism",
      technology: "HSS",
    },
    {
      fileExtension: "mpd",
      technology: "DASH",
    },
  ],
  publicationHomepageDynamicContentTimeframe: 2,
  publicationHomepageScreenFormat: [
    {
      value: "WEB",
      name: "Web",
      landscapeItems: 8,
      posterItems: 10,
      ratio: "1920/1080",
    },
    {
      value: "SMARTPHONE",
      name: "Smartphone",
      landscapeItems: 2,
      posterItems: 3,
      ratio: "360/640",
    },
    {
      value: "TABLET",
      name: "Tablet",
      landscapeItems: 4,
      posterItems: 5,
      ratio: "768/1024",
    },
    {
      value: "SMARTTV",
      name: "Smart TV",
      landscapeItems: 8,
      posterItems: 10,
      ratio: "1920/1080",
    },
  ],
  publicationHomepageTheme: [
    {
      value: "default",
      backgroundColor: "#ffffff",
      textColor: "#000000",
    },
    {
      value: "beemix",
      backgroundColor: "#1d1e1c",
      textColor: "#f27930",
    },
  ],
  publicationEditorialSectionTypes: ["articles", "news", "pages"],
  vodCatalog: [
    {
      idCatalog: "1",
      cdnPlayIcon: true,
      originPlayIcon: true,
    },
  ],
  mediaTechnology: ["HLS", "DASH", "MP4", "TS", "MOV", "HSS"],
  csvDelimiter: ";",
  uploadAllowedFileTypesVideos: ".mpg,.mpeg,.ts,.mp4,.mp3,.mcp,.mxf,.mkv",
  uploadAllowedFileTypesImages: ".jpg,.jpeg,.svg,.png",
  debugWebsocket: true,
  debugPlayer: true,
  vodTaggingEnabled: true,
  currency: "USD",
  components: [
    {
      displayName: "dashboard",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "qa",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "tmdb",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "broadpeak",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "adobepass",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "npvr",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "akamai",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "gigya",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "cohabitation",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "tucano",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "cms",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "openapi",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "rabbitmq",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "consul",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "publication",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "adminui",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "spideo",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "analytics",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "catchup",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "reportv",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "search",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "crm",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "viaccess",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "recommendation",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "identity",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "smartsearch",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "studio",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "realtime",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "oidc",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "suboffer",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "entitlements",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "billing",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
  ],
};
