import * as iconMetadataFal_ from "../../build/fal/iconMetadata.json";
import * as iconMetadataFad_ from "../../build/fad/iconMetadata.json";
import * as iconMetadataTaui_ from "../../build/taui/iconMetadata.json";
import { IconMetaFile } from "../types.js";

export const iconMetadata = {
  fal: (iconMetadataFal_ as any).default as IconMetaFile,
  fad: (iconMetadataFad_ as any).default as IconMetaFile,
  taui: (iconMetadataTaui_ as any).default as IconMetaFile,
};
