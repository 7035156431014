export enum TauiServices {
  "analytics" = "analytics",
  "billing" = "billing",
  "catchup" = "catchup",
  "cms" = "cms",
  "crm" = "crm",
  "dashboard" = "dashboard",
  "entitlements" = "entitlements",
  "identity" = "identity",
  "npvr" = "npvr",
  "oidc" = "oidc",
  "privacy" = "privacy",
  "publication" = "publication",
  "realtime" = "realtime",
  "recommendation" = "recommendation",
  "studio" = "studio",
  "suboffer" = "suboffer",
  "proxy" = "proxy",
}

export type BaseServices = {
  displayName: string;
};

export interface Services {
  [service: string]: Service;
}

export interface Service {
  base: string;
  id: string;
  logo?: string;
  items?: ServiceItem[];
  services?: string[];
}

export interface ServiceItem {
  id: string;
  path?: string;
  permission?: string;
  items?: ServiceItem[];
  services?: string[];
}

export type TauiStructure = {
  baseServices: BaseServices[];
  services: Services;
};
