import { PropertyValues } from "lit";
import { TAUIDomEvent } from "../common/dom/fire_event";
import { Constructor } from "../types";
import { TauiBaseEl } from "./taui-base-mixin";
import {
  makeSideSheetManager,
  showSideSheet,
} from "../side-sheet/make-side-sheet-manager";

interface RegisterSideSheetParams {
  sideSheetShowEvent: keyof TAUIDomEvents;
  sideSheetTag: keyof HTMLElementTagNameMap;
  sideSheetImport: () => Promise<unknown>;
}

declare global {
  // for fire event
  interface TAUIDomEvents {
    "register-side-sheet": RegisterSideSheetParams;
  }
  // for add event listener
  interface HTMLElementEventMap {
    "register-side-sheet": TAUIDomEvent<RegisterSideSheetParams>;
  }
}

export const sideSheetManagerMixin = <T extends Constructor<TauiBaseEl>>(
  superClass: T
) =>
  class extends superClass {
    protected firstUpdated(changedProps: PropertyValues) {
      super.firstUpdated(changedProps);
      // deprecated
      this.addEventListener("register-side-sheet", (e) =>
        this.registerSideSheet(e.detail)
      );
      makeSideSheetManager(this, this.shadowRoot!);
    }

    private registerSideSheet({
      sideSheetShowEvent,
      sideSheetTag,
      sideSheetImport,
    }: RegisterSideSheetParams) {
      this.addEventListener(sideSheetShowEvent, (showEv) => {
        showSideSheet(
          this,
          this.shadowRoot!,
          sideSheetTag,
          (showEv as TAUIDomEvent<unknown>).detail,
          sideSheetImport
        );
      });
    }
  };
