import { TopAppBarFixed } from "@material/mwc-top-app-bar-fixed/mwc-top-app-bar-fixed";
import { css, CSSResultGroup } from "lit";
import { customElement } from "lit/decorators";

@customElement("taui-top-app-bar-fixed")
// @ts-expect-error
export class TauiTopAppBarFixed extends TopAppBarFixed {
  public firstUpdated() {
    super.firstUpdated();
  }

  protected static get styles(): CSSResultGroup {
    return [
      TopAppBarFixed.styles,
      css`
        .mdc-top-app-bar {
          position: absolute;
        }
        .mdc-top-app-bar .mdc-top-app-bar__section .mdc-top-app-bar__title {
          padding-left: 0px;
        }
        .mdc-top-app-bar--fixed-adjust {
          padding-top: var(--taui-top-app-bar-fixed-row-height, 64px);
        }
        .mdc-top-app-bar .mdc-top-app-bar__row {
          height: var(--taui-top-app-bar-fixed-row-height, 64px);
        }
        .mdc-top-app-bar__section--align-start,
        .mdc-top-app-bar .mdc-top-app-bar__section .mdc-top-app-bar__title {
          width: 100%;
        }
        .mdc-top-app-bar .mdc-top-app-bar__section {
          min-width: auto;
          padding: 0;
        }
      `,
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-top-app-bar-fixed": TauiTopAppBarFixed;
  }
}
