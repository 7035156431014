import { TucanoAdminUI } from "../../types";
import { AssemblingParams, MediaDTO } from "../../openapi/studio-api";
import { TauiServices } from "../../types/structure";

export const studioAssembleChunks = async (
  taui: TucanoAdminUI,
  idMedia: string,
  params: AssemblingParams
) =>
  taui.callApi<void>(
    "POST",
    TauiServices.studio,
    `/media/${idMedia}/upload/assemble`,
    "studio.media.update",
    params
  );

export const studioResetUpload = async (taui: TucanoAdminUI, idMedia: string) =>
  taui.callApi<MediaDTO>(
    "PUT",
    TauiServices.studio,
    `/media/${idMedia}/upload/reset`,
    "studio.asset.update"
  );
